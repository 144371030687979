import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { initializeApp } from 'firebase/app';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import styled from 'styled-components';
import { Box, LinearProgress, Link as AnchorLink, CircularProgress } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import PageLayout from '../components/PageLayout';
import BackButton from '../components/BackButton';
import Typography from '../components/Typography';
import Button from '../components/Button';
import Separator from '../components/Separator';
import SiteHelmet from '../components/SiteHelmet';
import useQuery from '@/hooks/useQuery';
import superagent from 'superagent';
import prefix from 'superagent-prefix';

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

const db = getFirestore(firebaseApp);

function mockDataTransWhenDev(transactionId) {
  if (process.env.REACT_APP_ENV !== 'production') {
    superagent
      .post('/api/webhooks/datatrans')
      .send({
        transactionId,
        status: 'settled',
        paymentMethod: 'PAP',
      })
      .use(prefix(process.env.REACT_APP_API_MAIN_URL))
      .then(() => {})
      .catch(() => {});
  }
}

const DownloadButton = styled(Button)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: ${({ theme }) => theme.palette.grey[600]};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.grey[600]};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const Ellipsis = styled.div`
  display: inline-block;

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 3000ms infinite;
    animation: ellipsis steps(4, end) 3000ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 40px;
    }
  }
`;

const StyledSearchLink = styled(Link)`
  text-decoration: none;
`;

const StyledSearchButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grey[700]};
  transition: all ${transitions.easing.easeInOut} ${transitions.duration.standard}ms;

  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
  }
`;

function handleOnTransactionChanged(res, { setFileUrl, setLoadingState, setEmail }) {
  const data = res.data();
  if (!data) {
    setLoadingState('notFound');
    return;
  }

  const { status, zipUrl, user } = data;
  setEmail(user.email);

  switch (status) {
    case 'zip_creating':
      setLoadingState('processing');
      break;
    case 'zip_completed':
      setFileUrl(zipUrl);
      setLoadingState('completed');
      break;
    case 'zip_failed':
    case 'payment_failed':
    case 'payment_canceled':
      setLoadingState('failed');
      break;
    case 'zip_expired':
      setLoadingState('expired');
      break;
    default:
      break;
  }
}

const PaymentSuccessRoute = () => {
  const [t] = useTranslation();
  const query = useQuery();
  const [transactionId] = useState(query.get('datatransTrxId'));
  const [email, setEmail] = useState();
  const history = useHistory();

  const [loadingState, setLoadingState] = useState('loading');
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    if (!transactionId || !transactionId.length) {
      history.replace('/');
      return false;
    }
    mockDataTransWhenDev(transactionId);
    const firebasePath = `/Products/TerrainExportZip/Transactions/${transactionId}`;
    const transactionDocRef = doc(db, firebasePath);
    return onSnapshot(transactionDocRef, (res) =>
      handleOnTransactionChanged(res, {
        setLoadingState,
        setFileUrl,
        setEmail,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const toPage = () => {
    history.push('/map/2d');
  };

  return (
    <>
      <PageLayout>
        {loadingState === 'loading' && (
          <>
            <SiteHelmet pageTitle={t('pages:loading')} />
            <Box display='flex' justifyContent='center' my={2}>
              <CircularProgress size={30} />
            </Box>
          </>
        )}
        {loadingState === 'processing' && (
          <>
            <SiteHelmet pageTitle={t('pages:processing')} />
            <Box mb={2}>
              <Typography component='h1' variant='h2' fontWeight={500}>
                {t('successRoute:processing')}
                <Ellipsis />
              </Typography>
            </Box>
            <Box mt={3} mb={3}>
              <LinearProgress />
            </Box>
            <Box mb={2}>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:sentTo')} {email}. {t('successRoute:wait')}
              </Typography>
            </Box>
            <Box my={2}>
              <Separator />
            </Box>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:transactionID')}: {transactionId}
              </Typography>
            </Box>
          </>
        )}
        {loadingState === 'expired' && (
          <>
            <SiteHelmet pageTitle={t('pages:expired')} />
            <Box mb={2}>
              <BackButton close onClick={toPage}>
                {t('successRoute:close')}
              </BackButton>
            </Box>
            <Box mb={2}>
              <Typography component='h1' variant='h2' fontWeight={500}>
                {t('successRoute:expired')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:expiredContinue')}
              </Typography>
            </Box>
            <Box>
              <Button onClick={toPage} size='large' color='primary' variant='contained'>
                {t('successRoute:searchNew')}
              </Button>
            </Box>
          </>
        )}
        {loadingState === 'failed' && (
          <>
            <SiteHelmet pageTitle={t('pages:exportFailed')} />
            <Box mb={2}>
              <BackButton close onClick={toPage}>
                {t('successRoute:close')}
              </BackButton>
            </Box>
            <Box mb={2}>
              <Typography component='h1' variant='h2' fontWeight={500}>
                {t('successRoute:exportFailed')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:exportProblem')}
              </Typography>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:contactSupport')}{' '}
                <StyledAnchorLink href={`mailto:info@amenti.ch?subject=Issue with terrain data export #${transactionId} - Data.Amenti`} color='secondary'>
                  info@amenti.ch
                </StyledAnchorLink>{' '}
                {t('successRoute:furtherAssistance')}
              </Typography>
            </Box>
            <Box mb={3}>
              <AnchorLink href={`mailto:info@amenti.ch?subject=Issue with terrain data export #${transactionId} - Data.Amenti`} download underline='none'>
                <DownloadButton color='primary' variant='contained' size='large'>
                  {t('successRoute:contact')}
                </DownloadButton>
              </AnchorLink>
            </Box>
            <Box my={2}>
              <Separator />
            </Box>
            <Box mb={2}>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:transactionID')}: {transactionId}
              </Typography>
            </Box>
          </>
        )}
        {loadingState === 'completed' && (
          <>
            <SiteHelmet pageTitle={t('pages:completed')} />
            <Box mb={2}>
              <BackButton close onClick={toPage}>
                {t('successRoute:close')}
              </BackButton>
            </Box>
            <Box mb={2}>
              <Typography component='h1' variant='h2' fontWeight={500}>
                {t('successRoute:ready')}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:canDownload')}
              </Typography>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:emailSent')} {email}.
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:transactionID')}: <span>{transactionId}</span>
              </Typography>
            </Box>
            <Box mb={3}>
              <AnchorLink href={fileUrl} download underline='none'>
                <DownloadButton color='primary' variant='contained' size='large'>
                  {t('successRoute:download')}
                </DownloadButton>
              </AnchorLink>
            </Box>
            <Box mb={3}>
              <StyledLink to={`${process.env.PUBLIC_URL}/pdf/Handbuch_Datenimport_in_ArchiCAD.pdf`} target='_blank'>
                {t('faq:archiCadImport:title')}
              </StyledLink>
            </Box>
            <Box mb={3}>
              <Separator />
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:havingProblems')}{' '}
                <StyledAnchorLink href={`mailto:info@amenti.ch?subject=Issue with terrain data export #${transactionId} - Data.Amenti`}>
                  info@amenti.ch
                </StyledAnchorLink>
              </Typography>
            </Box>
            <Box mb={3}>
              <StyledSearchLink to='/map/2d'>
                <StyledSearchButton color='secondary' variant='outlined' size='large'>
                  {t('successRoute:searchNew')}
                </StyledSearchButton>
              </StyledSearchLink>
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:questions')} <StyledLink to='/faq'>{t('successRoute:faq')}</StyledLink>
              </Typography>
            </Box>
          </>
        )}
        {loadingState === 'notFound' && (
          <>
            <SiteHelmet pageTitle={t('pages:orderNotFound')} />
            <Box mb={2}>
              <BackButton close onClick={toPage}>
                {t('successRoute:close')}
              </BackButton>
            </Box>
            <Box mb={2}>
              <Typography component='h1' variant='h2' fontWeight={500}>
                {t('successRoute:notFound')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body1'>
                {t('successRoute:contactSupport')}{' '}
                <StyledAnchorLink href='mailto:info@amenti.ch' color='secondary'>
                  info@amenti.ch
                </StyledAnchorLink>{' '}
                {t('successRoute:orContinue')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Button onClick={toPage} size='large' color='primary' variant='contained'>
                {t('successRoute:searchNew')}
              </Button>
            </Box>
            <Box mb={3}>
              <Separator />
            </Box>
            <Box mb={2}>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:havingProblems')}{' '}
                <StyledAnchorLink href={`mailto:info@amenti.ch?subject=Issue with terrain data export #${transactionId} - Data.Amenti`}>
                  info@amenti.ch
                </StyledAnchorLink>
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography color='textSecondary' variant='body2'>
                {t('successRoute:questions')} <StyledLink to='/faq'>{t('successRoute:faq')}</StyledLink>
              </Typography>
            </Box>
          </>
        )}
        {/*<Box my={3}>*/}
        {/*  {fileHasLoaded && (*/}
        {/*    <Box display='flex'>*/}
        {/*      <Box mr={0.5}>*/}
        {/*        <Typography color='textSecondary' variant='body2'>*/}
        {/*          Didn’t recieve the data on m.limbach@amenti.ch?*/}
        {/*        </Typography>*/}
        {/*      </Box>*/}
        {/*      {!resendOpen && (*/}
        {/*        <ResendButton*/}
        {/*          variant='text'*/}
        {/*          onClick={() => setResendOpen(true)}*/}
        {/*        >*/}
        {/*          <Typography color='textSecondary' variant='body2'>*/}
        {/*            Resend the email.*/}
        {/*          </Typography>*/}
        {/*        </ResendButton>*/}
        {/*      )}*/}
        {/*    </Box>*/}
        {/*  )}*/}

        {/*  {(resendOpen && fileHasLoaded) && (*/}
        {/*    <Box mt={1}>*/}
        {/*      <Field*/}
        {/*        label='E-mail address'*/}
        {/*        labelInside*/}
        {/*        size='medium'*/}
        {/*        placeholder='myemail@mail.com'*/}
        {/*        value={email}*/}
        {/*        changeHandler={(value) => emailHandler(value)}*/}
        {/*        type='email'*/}
        {/*        InputProps={{*/}
        {/*          endAdornment: (*/}
        {/*            <Box p={1}>*/}
        {/*              {validateEmail(email) ? (*/}
        {/*                <Button*/}
        {/*                  onClick={() => {*/}
        {/*                  }}*/}
        {/*                  size='small'*/}
        {/*                  color='primary'*/}
        {/*                  variant='contained'*/}
        {/*                >*/}
        {/*                  Send*/}
        {/*                </Button>*/}
        {/*              ) : (*/}
        {/*                <Tooltip*/}
        {/*                  title='Enter a valid e-mail address'*/}
        {/*                  arrow*/}
        {/*                  placement='top'*/}
        {/*                >*/}
        {/*                  <Box display='inline-block'>*/}
        {/*                    <Button*/}
        {/*                      onClick={() => {*/}
        {/*                      }}*/}
        {/*                      size='small'*/}
        {/*                      color='primary'*/}
        {/*                      variant='contained'*/}
        {/*                      disabled={true}*/}
        {/*                    >*/}
        {/*                      Send*/}
        {/*                    </Button>*/}
        {/*                  </Box>*/}
        {/*                </Tooltip>*/}
        {/*              )}*/}
        {/*            </Box>*/}
        {/*          ),*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*  )}*/}
        {/*</Box>*/}
      </PageLayout>
    </>
  );
};
export default PaymentSuccessRoute;
